<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img width="300"
                    class="img-fluid for-light"
                    src="../assets/images/logo/running-logo-login.png"
                    alt="looginpage"
                  />
                  <img width="300"
                    class="img-fluid for-dark"
                    src="../assets/images/logo/logo_dark.png"
                    alt="looginpage"
                  />
                </a>
              </div>
              <div class="login-main">
                <form class="theme-form" @submit.prevent="login">
                  <h4>Sign in to account</h4>
                  <p>Enter your email & password to login</p>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input
                      class="form-control"
                      type="email"
                      required=""
                      placeholder="your@gmail.com"
                      v-model="this.email"
                    />
                    
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input
                        class="form-control"
                        type="password"
                        name="login[password]"
                        required=""
                        placeholder="*********"
                        v-model="this.password"
                      />
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox" />
                      <label class="text-muted" for="checkbox1"
                        >Remember password</label
                      >
                    </div>
                    <!-- <router-link class="link" to="/authentication/forgetpassword"> Forgot password?</router-link> -->
                    <div class="text-end mt-3">
                      <button
                        class="btn btn-primary btn-block w-100"
                        type="submit"
                      >
                      <vue-feather type="loader" size="18" animation="spin" v-if="this.is_login ==='Y'"></vue-feather> Sign in
                      </button>
                    </div>
                  </div>
                  <h6 class="text-muted mt-4"></h6>

                  <p class="mt-4 mb-0 text-center">
                    Don't have account?<a class="ms-2" href="/auth/register"
                      >Create Account</a
                    >
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.validate-error {
  color: red;
}
</style>

<script>
import axios from "axios";
import { useUserStore } from "../store/modules/user";
import { useCookies } from "vue3-cookies";
export default {
  name: "login",
  setup() {
    const user_store = useUserStore();
    user_store.loadFromLocalStorage();
    const { cookies } = useCookies();
    return { user_store,cookies };
  },
  data() {
    return {
      user: {},
      email: "",
      password: "",
      is_login: ""
    };
  },

  created() {
    //this.user_store.clear();
  },

  methods: {
    async login() {
      this.is_login = "Y"
      try {
        let data = {
          email: this.email,
          password: this.password,
        }

        const response = await axios.post("/v1/auth/login", data);

        //console.log("response", response)
        
        //localStorage.setItem("_rr_user", JSON.stringify(response.data.body));
        if (response.data.status === "success") {

          localStorage.setItem("jwt", response.data.accessToken);
          localStorage.setItem("user_id", response.data.body.user_id);
          localStorage.setItem("user", JSON.stringify(response.data.body));
          this.is_login = "N";
          this.$router.push("/");
        }else{
          this.is_login = "N";
          alert("Login error, invalid credentials");
        }

        
      } catch (error) {
        this.is_login = "N";
        alert("พบข้อผิดพลาด! กรุณา login อีกครั้ง");
        this.$router.push({ path: "/auth/login" });
      }
    },
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>
