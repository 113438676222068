<template>
  <div class="row">&nbsp;</div>
  <div
    v-if="
      this.user.role_name === 'system' || this.user.role_name === 'systemadmin'
    "
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="row">
            <!-- <WelcomeCard /> -->
            <div class="col-xxl-12">
              <div class="row widget-grid">
                <div class="card profile-box">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="greeting-user">
                          <h4 class="f-w-600 mb-0">
                            <vue-feather
                              type="bar-chart"
                              size="36"
                            ></vue-feather>
                            SYSTEM DASHBOARD
                          </h4>
                          <p>&nbsp;</p>
                          <!-- <p>{{ this.eventDetails.event_date }},{{ this.eventDetails.province }}</p> -->

                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                    <div class="cartoon">
                      <img
                        class="img-fluid"
                        src="@/assets/images/dashboard/cartoon.svg"
                        alt="vector women with leptop"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <AllCampaignsCard /> -->

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>คำขอถอนเงิน</b></h5>
                <button
                  type="button"
                  @click.prevent="loadCashTranfer()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th
                      v-if="this.user.role_name === 'systemadmin'"
                      style="text-align: center"
                    >
                      <b>PASS</b>
                    </th>
                    <th style="text-align: center"><b>RN</b></th>
                    <th style="text-align: left"><b>วันที่ถอนเงิน</b></th>
                    <th style="text-align: left"><b>OPN</b></th>
                    <th style="text-align: left"><b>Bank</b></th>
                    <th style="text-align: left"><b>ธนาคาร</b></th>
                    <th style="text-align: left"><b>เลขที่บัญชี</b></th>
                    <th style="text-align: center"><b>อีเมลล์</b></th>
                    <th style="text-align: center"><b>สถานะ</b></th>
                    <th style="text-align: center"><b>สั่งบชOPNโอนเงิน</b></th>
                    <th style="text-align: center"><b>โอนเงินแล้ว</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_cash_tranfer === 'Y'">
                  <tr>
                    <td colspan="11">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_cash_tranfer.length === 0 &&
                    this.loadding_cash_tranfer === 'N'
                  "
                >
                  <tr>
                    <td colspan="11">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_cash_tranfer.length !== 0">
                  <tr v-for="item in this.data_cash_tranfer" :key="item">
                    <td
                      v-if="this.user.role_name === 'systemadmin'"
                      scope="row"
                      style="text-align: center"
                    >
                      {{ item.password }}
                    </td>
                    <td scope="row" style="text-align: center">
                      RN{{ item.user_id }}-{{ item.bank_account_name }}-{{
                        item.display_name
                      }}
                    </td>
                    <td scope="row" style="text-align: left">
                      {{ item.request_date }}
                    </td>
                    <td style="text-align: left">
                      {{ item.amount }}
                    </td>
                    <td style="text-align: left">
                      {{ item.transfer_amount }}
                    </td>
                    <td style="text-align: left">
                      {{ item.bank_name }}
                    </td>
                    <td style="text-align: left">
                      {{ item.bank_account_no }}
                    </td>
                    <td style="text-align: left">
                      {{ item.email }}
                    </td>
                    <td style="text-align: left">
                      {{ item.status }}
                    </td>
                    <td style="text-align: center">
                      <button
                        class="btn btn-primary"
                        @click.prevent="opnTranfer(item.wd_key)"
                      >
                        Approve
                      </button>
                    </td>
                    <td style="text-align: center">
                      <button
                        class="btn btn-primary"
                        @click.prevent="transferCompleted(item.wd_key)"
                      >
                        Completed
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0 sm-3 mb-3"><b></b></h5>
                <form class="form-inline">
                  <div class="form-group">
                    <select class="form-select" v-model="order_filter_type">
                      <option value="mobile">mobile</option>
                      <option value="payment_ref">ref</option>
                      <option value="event_key">event_key</option>
                      <option value="uuid">order_id</option>
                      <option value="email">email</option>
                    </select>
                  </div>
                  &nbsp;
                  <div class="form-group">
                    <input
                      class="form-control"
                      v-model="order_filter_value"
                      type="text"
                      minlength="3"
                      placeholder="Filter value"
                    />
                  </div>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    @click.prevent="getLatestOrder()"
                    @submit.prevent="getLatestOrder()"
                    class="btn btn-primary"
                  >
                    <vue-feather type="filter" size="18"> </vue-feather>
                    Filter</button
                  >&nbsp;&nbsp;
                  <button
                    type="reset"
                    @click.prevent="clearLatestOrder()"
                    class="btn btn-primary"
                  >
                    <vue-feather type="refresh-cw" size="18"> </vue-feather>
                    Clear
                  </button>
                </form>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>คำสั่งซื้อ</b></th>
                    <th style="text-align: left"><b>สถานะ</b></th>
                    <th style="text-align: left"><b>จำนวน</b></th>
                    <th style="text-align: left"><b>ยอด</b></th>
                    <th style="text-align: center"><b>P</b></th>
                    <th style="text-align: center"><b>L</b></th>
                    <th style="text-align: center"><b>E</b></th>
                    <th style="text-align: center"><b>S</b></th>
                    <th style="text-align: left"><b>สั่งซื้อ</b></th>
                    <th style="text-align: left"><b>ชำระเงิน</b></th>
                    <th style="text-align: left"><b>กิจกรรม</b></th>
                    <th style="text-align: left"><b>อีเมลล์</b></th>
                    <th style="text-align: left"><b>เบอร์โทร</b></th>
                    <th style="text-align: left"><b>Ref</b></th>
                    <th style="text-align: left"><b>PC</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_latest_order === 'Y'">
                  <tr>
                    <td colspan="15">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_latest_order.length === 0 &&
                    this.loadding_latest_order === 'N'
                  "
                >
                  <tr>
                    <td colspan="15">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_latest_order.length !== 0">
                  <tr v-for="item in this.data_latest_order" :key="item">
                    <td scope="row" style="text-align: center">
                      <a
                        :href="'https://running.in.th/d/' + item.uuid"
                        target="_blank"
                        >{{ item.uuid }}</a
                      >
                    </td>
                    <td style="text-align: left">{{ item.status }}</td>
                    <td style="text-align: left">{{ item.image_length }}</td>
                    <td style="text-align: left">{{ item.amount }}</td>
                    <td style="text-align: center">
                      <div
                        v-if="
                          item.is_insert_order_paid_summary !== 'Y' &&
                          item.status === 'PAID'
                        "
                      >
                        <button class="btn btn-primary">X</button>
                      </div>
                      <div v-else>{{ item.is_insert_order_paid_summary }}</div>
                    </td>
                    <td style="text-align: center">
                      <div
                        v-if="
                          item.is_logo_image_created !== 'Y' &&
                          item.status === 'PAID'
                        "
                      >
                        <button class="btn btn-primary">X</button>
                      </div>
                      <div v-else>{{ item.is_logo_image_created }}</div>
                    </td>

                    <td style="text-align: center">
                      <div
                        v-if="
                          item.is_send_email !== 'Y' &&
                          item.email !== '' &&
                          item.status === 'PAID'
                        "
                      >
                        <button
                          class="btn btn-primary"
                          @click.prevent="
                            resendEmail(item.id, item.confirm_key)
                          "
                        >
                          X{{ item.is_send_email }}
                        </button>
                      </div>
                      <div v-else>{{ item.is_send_email }}</div>
                    </td>

                    <td style="text-align: center">
                      <div
                        v-if="
                          item.is_send_sms !== 'Y' && item.status === 'PAID'
                        "
                      >
                        <button
                          class="btn btn-primary"
                          @click.prevent="resendSMS(item.id, item.confirm_key)"
                        >
                          X{{ item.is_send_sms }}
                        </button>
                      </div>
                      <div v-else>{{ item.is_send_sms }}</div>
                    </td>

                    <td style="text-align: left">{{ item.create_date }}</td>
                    <td style="text-align: left">{{ item.payment_date }}</td>
                    <td style="text-align: left">
                      <a :href="'/order-admin/' + item.event_key">{{
                        item.event_key
                      }}</a>
                    </td>
                    <td style="text-align: left">{{ item.email }}</td>
                    <td style="text-align: left">{{ item.mobile }}</td>
                    <td style="text-align: left">{{ item.payment_ref }}</td>
                    <td style="text-align: left">{{ item.payment_channel }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>Logs</b></h5>
                <button
                  type="button"
                  @click.prevent="loadLatestLogs()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>ID</b></th>
                    <th style="text-align: left"><b>Service</b></th>
                    <th style="text-align: left"><b>Create Date</b></th>
                    <th style="text-align: center"><b>Request</b></th>
                    <th style="text-align: center"><b>Response</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_latest_logs === 'Y'">
                  <tr>
                    <td colspan="4">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_latest_logs.length === 0 &&
                    this.loadding_latest_logs === 'N'
                  "
                >
                  <tr>
                    <td colspan="4">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_latest_logs.length !== 0">
                  <tr v-for="item in this.data_latest_logs" :key="item">
                    <td>
                      <a
                        target="_blank"
                        :href="
                          this.s3_url+'/assest/facesearch/' +
                          item.id +
                          '.jpg'
                        "
                        >{{ item.id }}</a
                      >
                    </td>
                    <td>
                      {{ item.service }}
                    </td>
                    <td>
                      {{ item.create_date }}
                    </td>
                    <td>
                      {{ item.request }}
                    </td>
                    <td>
                      {{ item.response }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>สรุปยอดให้ช่างภาพ</b></h5>
                <button
                  type="button"
                  @click.prevent="loadOrderUnSummarize()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>ดำเนินการ</b></th>
                    <th style="text-align: left"><b>วันที่ชำระเงิน</b></th>
                    <th style="text-align: left"><b>กิจกรรม</b></th>
                    <th style="text-align: center"><b>สรุปออเดอร์</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_order_summarize === 'Y'">
                  <tr>
                    <td colspan="4">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_order_summarize.length === 0 &&
                    this.loadding_order_summarize === 'N'
                  "
                >
                  <tr>
                    <td colspan="4">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_order_summarize.length !== 0">
                  <tr v-for="item in this.data_order_summarize" :key="item">
                    <td scope="row" style="text-align: center">
                      <button
                        type="button"
                        @click.prevent="
                          reSendOrderUnSummarize(
                            item.id,
                            item.confirm_key,
                            item.event_key,
                            item.event_id
                          )
                        "
                        class="btn btn-primary"
                      >
                        <vue-feather type="refresh-cw" size="18"> </vue-feather>
                        Tricker
                      </button>
                    </td>
                    <td scope="row" style="text-align: left">
                      {{ item.payment_date }}
                    </td>
                    <td style="text-align: left">
                      <a
                        :href="'https://running.in.th/d/' + item.uuid"
                        target="_blank"
                        >{{ item.uuid }}</a
                      >
                    </td>
                    <td style="text-align: center">
                      {{
                        item.is_insert_order_paid_summary === null ? "N" : "Y"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>ส่งรูปให้ลูกค้า</b></h5>
                <button
                  type="button"
                  @click.prevent="loadOrderDelivered()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>ดำเนินการ</b></th>
                    <th style="text-align: left"><b>วันที่ชำระเงิน</b></th>
                    <th style="text-align: left"><b>ออเดอร์</b></th>
                    <th style="text-align: center"><b>อีเมลล์</b></th>
                    <th style="text-align: center"><b>SMS</b></th>
                    <th style="text-align: center"><b>รูปต้นฉบับ</b></th>
                    <th style="text-align: center"><b>รูป+Logo</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_order_delivered === 'Y'">
                  <tr>
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_order_delivered.length === 0 &&
                    this.loadding_order_delivered === 'N'
                  "
                >
                  <tr>
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_order_delivered.length !== 0">
                  <tr v-for="item in this.data_order_delivered" :key="item">
                    <td scope="row" style="text-align: center">
                      <button
                        type="button"
                        @click.prevent="reSendOrder(item.uuid)"
                        class="btn btn-primary"
                      >
                        <vue-feather type="refresh-cw" size="18"> </vue-feather>
                        Resend
                      </button>
                    </td>
                    <td scope="row" style="text-align: left">
                      {{ item.payment_date }}
                    </td>
                    <td style="text-align: left">
                      <a
                        :href="'https://running.in.th/d/' + item.uuid"
                        target="_blank"
                        >{{ item.uuid }}</a
                      >
                    </td>
                    <td style="text-align: center">
                      {{ item.is_send_email }}
                    </td>
                    <td style="text-align: center">
                      {{ item.is_send_sms }}
                    </td>
                    <td style="text-align: center">
                      {{ item.is_original_image_created }}
                    </td>
                    <td style="text-align: center">
                      {{ item.is_logo_image_created }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>Check Event</b></h5>
                <button
                  type="button"
                  @click.prevent="systemCheckEvent()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: left"><b>กิจกรรม</b></th>
                    <th style="text-align: left"><b>กรอบกิจกรรม</b></th>
                    <th style="text-align: left"><b>ลายน้ำ</b></th>
                    <th style="text-align: left"><b>ราคา</b></th>
                    <th style="text-align: left">
                      <b>เหมา|ราคา|caption|โหลดฟรี|ติดโลโก้ฟรี</b>
                    </th>
                    <th style="text-align: left">
                      <b>Drive|Line|colecttion</b>
                    </th>
                    <th style="text-align: left">
                      <b>ระบบ|ผู้จัด|ช่าง|เอเย่น|vat</b>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_check_event === 'Y'">
                  <tr>
                    <td colspan="7">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_check_event.length === 0 &&
                    this.loadding_check_event === 'N'
                  "
                >
                  <tr>
                    <td colspan="7">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_check_event.length !== 0">
                  <tr v-for="item in this.data_check_event" :key="item">
                    <td scope="row" style="text-align: left">
                      [{{ item.on_sale_start_text }}]<br />[{{
                        item.event_key
                      }}]<br />
                      {{ item.title }} <br />
                      <a target="_blank" :href="'/upload/' + item.event_key"
                        >[upload]</a
                      >
                      <br />
                      <a
                        target="_blank"
                        :href="'/event-adjustment-admin/' + item.event_key"
                        >[manage]</a
                      >
                      <br />
                      <a
                        target="_blank"
                        :href="'/order-admin/' + item.event_key"
                        >[report]</a
                      ><br />
                      <a target="_blank" :href="item.google_drive_path"
                        >[google_drive]</a
                      ><br />
                      <a target="_blank" :href="item.line_group_path"
                        >[line_group]</a
                      ><br />
                    </td>
                    <td
                      scope="row"
                      style="
                        text-align: left;
                        background-color: rgb(95, 95, 95);
                      "
                    >
                      <img
                        :src="item.event_frame_hori"
                        style="height: 100px; border: 5px solid #000000"
                      />
                      <img
                        :src="item.event_frame_verti"
                        style="height: 100px; border: 5px solid #000000"
                      />
                    </td>
                    <td
                      scope="row"
                      style="
                        text-align: left;
                        background-color: rgb(95, 95, 95);
                      "
                    >
                      <img
                        :src="item.wtm_hori"
                        style="height: 100px; border: 5px solid #000000"
                      />
                      <img
                        :src="item.wtm_verti"
                        style="height: 100px; border: 5px solid #000000"
                      />
                    </td>
                    <td scope="row" style="text-align: left">
                      <li
                        v-for="(_package, index) in item.package"
                        :key="index"
                      >
                        ({{ _package.price }})-{{ _package.name }}
                      </li>
                    </td>
                    <td scope="row" style="text-align: left">
                      {{ item.is_mhao }}
                    </td>
                    <td
                      style="text-align: left"
                      v-if="item.is_create_colecttion === 'Y'"
                    >
                      {{ item.google_drive }}|{{ item.line_group }}|{{
                        item.is_create_colecttion
                      }}
                    </td>
                    <td
                      style="text-align: left"
                      v-if="item.is_create_colecttion !== 'Y'"
                    >
                      {{ item.google_drive }}|{{ item.line_group }}|<button
                        class="btn btn-primary"
                        @click.prevent="createColection(item.event_key)"
                        type="button"
                      >
                        X
                      </button>
                    </td>
                    <td scope="row" style="text-align: left">
                      {{ item.share }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>Tag Proces</b></h5>
                <div class="row">
                  <select class="form-select" v-model="numberOfProcess">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="5000">5000</option>
                    <option value="10000">10000</option>
                  </select>
                </div>
                <button
                  type="button"
                  @click.prevent="systemTagProcess()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>วันที่จัดกิจกรรม</b></th>
                    <th style="text-align: left"><b>กิจกรรม</b></th>
                    <th style="text-align: center"><b>รูปทั้งหมด</b></th>
                    <th style="text-align: center"><b>แท็กแล้ว</b></th>
                    <th style="text-align: center"><b>กำลังแท็ก</b></th>
                    <th style="text-align: center"><b>เพิ่มงานลงคิวแท็ก</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_tag_process === 'Y'">
                  <tr>
                    <td colspan="6">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_tag_process.length === 0 &&
                    this.loadding_tag_process === 'N'
                  "
                >
                  <tr>
                    <td colspan="6">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_tag_process.length !== 0">
                  <tr v-for="item in this.data_tag_process" :key="item">
                    <td scope="row" style="text-align: center">
                      {{ item.event_date_text }}
                    </td>
                    <td scope="row" style="text-align: left">
                      {{ item.title }}
                      <a
                        target="_blank"
                        :href="'/event-adjustment-admin/' + item.event_key"
                        >[manage]</a
                      >
                      <a
                        target="_blank"
                        :href="'/order-admin/' + item.event_key"
                        >[report]</a
                      >
                    </td>
                    <td scope="row" style="text-align: center">
                      {{ Number(item.taged) + Number(item.process) }}
                    </td>
                    <td scope="row" style="text-align: center">
                      {{ item.taged }}
                    </td>
                    <td scope="row" style="text-align: center">
                      {{ item.process }}
                    </td>

                    <td style="text-align: center">
                      <button
                        class="btn btn-primary"
                        @click="
                          processQueueTag(
                            item.id,
                            item.event_key,
                            item.is_free_download,
                            item.is_add_watermark_for_free_download,
                            item.event_watermark_hori,
                            item.event_watermark_vertical
                          )
                        "
                      >
                        เพิม
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>ยอดขายโดยสรุป</b></h5>
                <button
                  type="button"
                  @click.prevent="reportSymmery()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>วันที่จัดกิจกรรม</b></th>
                    <th style="text-align: left"><b>กิจกรรม</b></th>
                    <!-- <th style="text-align: center"><b>ส่วนแบ่งช่างภาพ(%)</b></th> -->
                    <th style="text-align: center"><b>ออเดอร์</b></th>
                    <th style="text-align: center"><b>รูปที่ขาย</b></th>
                    <!-- <th style="text-align: center"><b>รายรับทั้งหมด</b></th> -->
                    <th style="text-align: center"><b>รายรับ</b></th>
                    <th style="text-align: center"><b>ภาษีมูลค่าเพิ่ม</b></th>
                    <th style="text-align: center"><b>ส่วนแบ่งช่างภาพ</b></th>
                    <th style="text-align: center"><b>ส่วนแบ่งระบบ</b></th>
                    <th style="text-align: center"><b>ผู้จัด</b></th>
                    <th style="text-align: center"><b>ผู้ดูแล</b></th>
                    <th style="text-align: center">
                      <b>ค่าธรรมเนียมจ่ายเงิน</b>
                    </th>
                    <th style="text-align: center"><b>จ่ายจริง</b></th>
                    <th style="text-align: center"><b>เข้าระบบ</b></th>

                    <th style="text-align: center"><b>เข้าระบบทั้งหมด</b></th>
                    <th style="text-align: center"><b>ค่าใช้จ่าย</b></th>

                    <th style="text-align: center"><b>รายได้เข้าระบบ</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_report_summery === 'Y'">
                  <tr>
                    <td colspan="17">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_report_summery.length === 0">
                  <tr>
                    <td colspan="17">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_report_summery.length !== 0">
                  <tr v-for="item in this.data_report_summery" :key="item">
                    <td style="text-align: center">
                      {{ item.event_date }}<br />
                      <a
                        target="_blank"
                        :href="'/event-adjustment-admin/' + item.event_key"
                        >[manage]</a
                      >
                      <br />
                      <a
                        target="_blank"
                        :href="'/order-admin/' + item.event_key"
                        >[report]</a
                      ><br />
                    </td>
                    <td scope="row" style="text-align: left">
                      {{ item.title }}<button v-if="item.uploadable==='Y'" class="btn btn-primary" style="padding-left: 10px;padding-right: 10px;" @click.prevent="closeEvent(item.event_key)" type="button">X</button>
                    </td>
                    <!-- <td style="text-align: center">
                    x
                  </td> -->
                    <td style="text-align: center">
                      {{ item.order_count }}
                    </td>
                    <td style="text-align: center">
                      {{ item.photo_count }}
                    </td>
                    <!-- <td style="text-align: center">
                    {{ item.paid_amount.toFixed(1) }}
                  </td> -->
                    <td style="text-align: center">
                      {{ item.amount.toFixed(1) }}
                    </td>
                    <td style="text-align: center">
                      {{ item.vat }}%<br />
                      {{ item.net_vat.toFixed(1) }}
                    </td>
                    <td style="text-align: center">
                      {{ item.photographer_share }}%<br />
                      {{ item.net_photographer.toFixed(1) }}
                    </td>
                    <td style="text-align: center">
                      {{ item.system_share }}%<br />
                      {{ item.system_amt.toFixed(1) }}
                    </td>
                    <td style="text-align: center">
                      {{ item.organizer_share }}%<br />
                      {{ item.organizershare_amt }}
                    </td>
                    <td style="text-align: center">
                      {{ item.salespersonshare }}%<br />
                      {{ item.salespersonshare_amt.toFixed(1) }}
                    </td>
                    <td style="text-align: center">
                      {{ item.total_payment_gateway_fee.toFixed(1) }}
                    </td>
                    <td style="text-align: center">
                      {{ item.net_payment_gateway_fee.toFixed(1) }}
                    </td>
                    <td style="text-align: center">
                      {{ item.net_payment_gateway_system_fee.toFixed(1) }}
                    </td>

                    <td style="text-align: center">
                      {{
                        Number(
                          item.system_amt + item.net_payment_gateway_system_fee
                        ).toFixed(1)
                      }}
                    </td>
                    <td style="text-align: center">
                      {{ item.expenses }}
                    </td>

                    <td style="text-align: center">
                      {{
                        (
                          item.system_amt +
                          item.net_payment_gateway_system_fee -
                          item.expenses
                        ).toFixed(1)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b></b></h5>
                <div class="m-0"></div>
                <div class="m-0">
                  <form class="form-inline">
                    <div class="form-group mb-2">
                      <b>ImageID</b>
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                      <input
                        class="form-control"
                        v-model="image_id_list"
                        type="text"
                        placeholder="ImageID ex. 1234,1345,5567"
                      />
                    </div>
                    <button
                      type="submit"
                      @click.prevent="getLinkDownload()"
                      @submit.prevent="getLinkDownload()"
                      class="btn btn-primary"
                    >
                      <vue-feather type="search" size="18"> </vue-feather>
                      ค้นหา
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>รหัสรูปภาพ</b></th>
                    <th style="text-align: center"><b>ดาวน์โหลด</b></th>
                    <th style="text-align: center"><b>ดาวน์โหลด+logo</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_link_download === 'Y'">
                  <tr>
                    <td colspan="3">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_link_download.length === 0 &&
                    this.loadding_link_download === 'N'
                  "
                >
                  <tr>
                    <td colspan="6">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_link_download.length !== 0">
                  <tr v-for="item in this.data_link_download" :key="item">
                    <td scope="row" style="text-align: center">
                      <img width="150" :src="item.upload_file_name" />
                    </td>

                    <td style="text-align: center">
                      <button
                        class="btn btn-primary"
                        @click.prevent="download(item.upload_file_name)"
                      >
                        ดาวน์โหลด</button
                      ><br />{{ item.id }}
                    </td>
                    <td style="text-align: center">
                      <button class="btn btn-primary" disabled>
                        ดาวน์โหลด+logo
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b></b></h5>
                <div class="m-0"></div>
                <div class="m-0">
                  <form class="form-inline">
                    <div class="form-group mx-sm-3 mb-2">
                      <select class="form-select" v-model="manual_notify_description">
                        <option value="RN_PROMPTPAY">RN_PROMPTPAY</option>
                        <option value="RN_CREDITCARD">RN_CREDITCARD</option>
                      </select>
                      
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                      <select class="form-select" v-model="manual_notify_is_reset_amt">
                        <option value="Y">ปรับยอดเงินเป็น 0</option>
                        <option value="N">ไม่ ปรับยอดเงินเป็น 0</option>
                      </select>
                      
                    </div>
                    <div class="form-group mb-2">
                      <b>ORDERID</b>
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                      <input
                        class="form-control"
                        v-model="manual_notify_order_id"
                        type="text"
                        
                      />
                    </div>
                    <button
                      type="submit"
                      @click.prevent="manualNotify()"
                      @submit.prevent="manualNotify()"
                      class="btn btn-primary"
                    >
                      <vue-feather v-if="this.loadding_manual_notify === 'Y'" animation="spin" type="loader" animation-speed="slow" size="15"> </vue-feather>
                      ทำรายการ
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <div v-if="this.user.role_name === 'photographer'">
    <div class="d-flex justify-content-center">this page is not authorized</div>
  </div>
</template>
<style scoped>
.table-fit {
  width: 1px;
}
</style>
<script>
import axios from "axios";
export default {
  data() {
    return {
      loadding_order_delivered: "N",
      data_order_delivered: [],

      loadding_order_summarize: "N",
      data_order_summarize: [],
      sk_summarize: "",

      loadding_cash_tranfer: "N",
      data_cash_tranfer: [],

      loadding_tag_process: "N",
      data_tag_process: [],
      numberOfProcess: 5,

      loadding_check_event: "N",
      data_check_event: [],

      loadding_report_summery: "N",
      data_report_summery: [],

      loadding_latest_order: "N",
      data_latest_order: [],

      loadding_link_download: "N",
      data_link_download: [],

      image_id_list: null,
      user: {},

      loadding_latest_logs: "N",
      data_latest_logs: [],

      order_filter_type: "mobile",
      order_filter_value: "",

      loadding_manual_notify: "N",
      manual_notify_is_reset_amt: "Y",
      manual_notify_order_id: null,
      manual_notify_description: "RN_PROMPTPAY",
      s3_url: process.env.VUE_APP_AWS_S3_URL,	
      token: localStorage.getItem("jwt")
    };
  },
  async mounted() {
    try {
      this.token = localStorage.getItem("jwt")
      this.user = JSON.parse(localStorage.getItem("user"));

      if (this.user.role_name === "system") {
        //this.loadCashTranfer();
        //this.loadOrderUnSummarize();
        //this.loadOrderDelivered();
        this.getLatestOrder();
        //this.systemTagProcess();
        //this.reportSymmery();
      }

      if (this.user.role_name === "systemadmin") {
        this.loadCashTranfer();
        //this.loadOrderUnSummarize();
        //this.loadOrderDelivered();
        this.getLatestOrder();
        //this.systemTagProcess();
        //this.reportSymmery();
      }
    } catch (error) {
      alert("พบข้อผิดพลาด กรุณาโหลดหน้าใหม่");
      //this.$router.push({ path: "/auth/login" });
    }
  },
  methods: {
    async loadOrderDelivered() {
      this.loadding_order_delivered = "Y";
      this.data_order_delivered = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/running-order-paid-creation-msg-list",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        this.data_order_delivered = response.data.body;

        this.loadding_order_delivered = "N";
      } else {
        alert("loadOrderDelivered พบข้อผิดพลาด!");
      }
    },
    async reSendOrder(uuid) {
      let data = {
        order_uuid: uuid,
      };
      const response = await axios.post(
        "v1/admin/running-order-paid-creation-msg",
        data
      );
      if (response.data.status === "success") {
        alert("ดำเนินการเรียบร้อยแล้ว");
      } else if (response.data.status === "refer") {
        alert("รายการนี้สำเร็จแล้ว");
      } else {
        alert("ส่งคำข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ!");
      }
    },
    async loadOrderUnSummarize() {
      this.loadding_order_summarize = "Y";
      this.data_order_summarize = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/running-order-summarize-list",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        this.data_order_summarize = response.data.body;
        this.sk_summarize = response.data.sk;
        this.loadding_order_summarize = "N";
      } else {
        alert("loadOrderUnSummarize พบข้อผิดพลาด!");
      }
    },
    async reSendOrderUnSummarize(_id, _confirm_key, _event_key, _event_id) {
      let data = {
        order_id: _id,
        confirm_key: _confirm_key,
        event_key: _event_key,
        event_id: _event_id,
        sk: this.sk_summarize,
      };
      const response = await axios.post("/v1/order-summery/summery", data);
      if (response.data.status === "success") {
        alert("ดำเนินการเรียบร้อยแล้ว");
      } else if (response.data.status === "refer") {
        alert("รายการนี้สำเร็จแล้ว");
      } else {
        alert("ส่งคำข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ!");
      }
    },
    async loadCashTranfer() {
      this.loadding_cash_tranfer = "Y";
      this.data_cash_tranfer = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post("v1/admin/running-cash-transfer", data,{
        headers
      });
      if (response.data.status === "success") {
        this.data_cash_tranfer = response.data.body;

        this.loadding_cash_tranfer = "N";
      } else {
        alert("loadCashTranfer พบข้อผิดพลาด!");
      }
    },
    async systemTagProcess() {
      this.loadding_tag_process = "Y";
      this.data_tag_process = [];
      let data = {
        event_key: "",
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post("v1/admin/system-dashboard-tag", data, {
        headers
      });
      if (response.data.status === "success") {
        this.data_tag_process = response.data.body;

        this.loadding_tag_process = "N";
      } else {
        alert("systemTagProcess พบข้อผิดพลาด!");
      }
    },
    async systemCheckEvent() {
      this.loadding_check_event = "Y";
      this.data_check_event = [];
      let data = {
        event_key: "",
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/system-dashboard-check-event",
        data,
        {
         headers
        }
      );
      if (response.data.status === "success") {
        this.data_check_event = response.data.body;

        this.loadding_check_event = "N";
      } else {
        alert("check_event พบข้อผิดพลาด!");
      }
    },
    async closeEvent(_event_key) {
      let data = {
        event_key: _event_key,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post("v1/utils/close-event", data, {
        headers
      });
      //console.log("response", response);
      if (response.data.status === "success") {
        this.reportSymmery();
      } else {
        alert("closeEvent พบข้อผิดพลาด! " + response.data.message);
      }
    },
    async createColection(_event_key) {
      //console.log("event_key:" + _event_key);
      let data = {
        event_key: _event_key,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post("v1/utils/colection-create", data, {
        headers
      });
      //console.log("response", response);
      if (response.data.status === "success") {
        this.systemCheckEvent();
      } else {
        alert("createColection พบข้อผิดพลาด! " + response.data.message);
      }
    },
    async reportSymmery() {
      this.loadding_report_summery = "Y";
      this.data_report_summery = [];
      let data = {
        event_key: "",
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/dashboard-report-summery",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        this.data_report_summery = response.data.body;

        this.loadding_report_summery = "N";
      } else {
        alert("reportSymmery พบข้อผิดพลาด! ");
      }
    },
    async processQueueTag(
      _event_id,
      _event_key,
      _is_free_download,
      _is_add_watermark_for_free_download,
      _event_watermark_hori,
      _event_watermark_vertical
    ) {
      if (this.numberOfProcess === 0) {
        alert("เลือกจำนวนที่ต้องการ Process");
        return false;
      }
      if (
        confirm(
          "ต้องการ put message q จำนวน " +
            this.numberOfProcess +
            " messge event [" +
            _event_key +
            "] ?"
        )
      ) {
        let data = {
          limit: this.numberOfProcess,
          event_id: _event_id,
          event_key: _event_key,
          event_watermark_hori: _event_watermark_hori,
          event_watermark_vertical: _event_watermark_vertical,
          is_free_download: _is_free_download,
          is_add_watermark_for_free_download:
            _is_add_watermark_for_free_download,
        };
        const response = await axios.post(
          "v1/upload/send-message-manual",
          data
        );
        if (response.data.status === "success") {
          alert("ดำเนินการเรียบร้อย: " + response.data.message);
        } else {
          alert(
            "ส่งคำข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ! " + response.data.message
          );
        }
      }
    },
    async clearLatestOrder() {
      this.order_filter_value = "";
      this.getLatestOrder();
    },
    async getLatestOrder() {
      if (
        this.order_filter_value.length == 0 ||
        this.order_filter_value.length >= 3
      ) {
        this.loadding_latest_order = "Y";
        this.data_latest_order = [];
        let data = {
          event_key: this.$route.params.q,
          order_filter_type: this.order_filter_type,
          order_filter_value: this.order_filter_value,
          strcd: localStorage.getItem("consent_eventflg"),
        };
        const headers = {
         'Authorization': 'Bearer ' + this.token
          };
        const response = await axios.post(
          "v1/admin/running-order-latest",
          data,
          {
            headers
          }
        );
        if (response.data.status === "success") {
          this.data_latest_order = response.data.body;
          this.sk_summarize = response.data.sk;
          this.loadding_latest_order = "N";
        } else {
          alert("get latest_order พบข้อผิดพลาด!");
        }
      }
    },
    async resendEmail(_id, _confirm_key) {
      let data = {
        order_id: _id,
        confirm_key: _confirm_key,
        sk: "QZ3c3mX9EIVFZS4O4h88EYygIMWnmVm4",
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post("v1/cust-noti/email-noti", data, {
        headers
      });
      this.getLatestOrder();
    },
    async resendSMS(_id, _confirm_key) {
      let data = {
        order_id: _id,
        confirm_key: _confirm_key,
        sk: "QZ3c3mX9EIVFZS4O4h88EYygIMWnmVm4",
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post("v1/cust-noti/sms-noti", data, {
        headers
      });
      this.getLatestOrder();
    },
    async getLinkDownload() {
      this.loadding_link_download = "Y";
      this.data_link_download = [];
      let data = {
        image_id: this.image_id_list,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/running-get-link-download",
        data,
        {
          headers
        }
      );

      if (response.data.status === "success") {
        this.data_link_download = response.data.body;
        this.sk_summarize = response.data.sk;
        this.loadding_link_download = "N";
      } else {
        this.loadding_link_download = "N";
        alert("get link_download พบข้อผิดพลาด!");
      }
    },
    async download(url) {
      window.open(url, "_blank");
    },
    async transferCompleted(_wd_key) {
      if (confirm("โอนเงินแล้ว?")) {
        let data = {
          wd_key: _wd_key,
        };
        const headers = {
         'Authorization': 'Bearer ' + this.token
      };
        const response = await axios.post(
          "v1/admin/running-cash-transfer-done",
          data,
          {
           headers
          }
        );

        if (response.data.status === "success") {
          //alert("Update ข้อมูลเรียบร้อย");
          this.loadCashTranfer();
        } else {
          alert("Update ข้อมูลพบข้อผิดพลาด!");
          this.loadCashTranfer();
        }
      }
    },
    async opnTranfer(_wd_key) {
      if (confirm("โอนผ่าน OPN เงินแล้ว?")) {
        let data = {
          wd_key: _wd_key,
        };
        const headers = {
         'Authorization': 'Bearer ' + this.token
      };
        const response = await axios.post(
          "v1/admin/running-cash-transfer-approve",
          data,
          {
            headers
          }
        );

        if (response.data.status === "success") {
          //alert("Update ข้อมูลเรียบร้อย");
          this.loadCashTranfer();
        } else {
          alert("Update ข้อมูลพบข้อผิดพลาด!");
          this.loadCashTranfer();
        }
      }
    },

    async loadLatestLogs() {
      this.loadding_latest_logs = "Y";
      this.data_latest_logs = [];
      let data = {
        event_key: "",
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post("v1/admin/running-latest-logs", data, {
        headers
      });
      if (response.data.status === "success") {
        this.data_latest_logs = response.data.body;

        this.loadding_latest_logs = "N";
      } else {
        alert("latest_logs พบข้อผิดพลาด!");
      }
    },
    async manualNotify() {
      this.loadding_manual_notify = "Y";
      if(this.manual_notify_order_id === null){
        alert("กรุณาตรวจสอบการ Manual notify: "+this.manual_notify_order_id)
        this.loadding_manual_notify = "N";
        return;
      }

      let data = {
        uuid: this.manual_notify_order_id,
        is_reset_amt: this.manual_notify_is_reset_amt
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post("v1/utils/manual-payment", data, {
        headers
      });
      if (response.data.status === "success") {
        alert(JSON.stringify(response.data))
        this.loadding_manual_notify = "N";
      } else {
        alert(JSON.stringify(response.data))
        this.loadding_manual_notify = "N";
      }
    },
  },
};
</script>
