<template>
  <div class="row">&nbsp;</div>
  <div v-if="this.page_permission === 'Y'">
    <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="row">
          <!-- <WelcomeCard /> -->
          <div class="col-xxl-12">
            <div class="row widget-grid">
              
                <div class="card profile-box">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="greeting-user">
                          <h4 class="f-w-600 mb-0"><vue-feather type="bar-chart" size="36"></vue-feather> RUNNING ADJUSTMENT (ADMIN)</h4>
                          <p>&nbsp;</p>
                          <!-- <p>{{ this.eventDetails.event_date }},{{ this.eventDetails.province }}</p> -->

                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                    <div class="cartoon">
                      <img
                        class="img-fluid"
                        src="@/assets/images/dashboard/cartoon.svg"
                        alt="vector women with leptop"
                      />
                    </div>
                  </div>
                </div>
             
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <div class="header-top">
              <h5 class="m-0"><b>ข้อมูลกิจกรรม (ADMIN) <a :href="'/order-admin/'+this.$route.params.q" >[REPORT]</a>  </b></h5>
              
              
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody >
                <tr>
                  <td>ชื่อกิจกรรม</td>
                  <td><input type="text" class="form-control" id="txt_title" v-model="txt_title" ></td>
                  <td>สถานที่จัดกิจกรรม</td>
                  <td><input type="text" class="form-control" id="txt_province" v-model="txt_province" ></td>
                </tr>
                <tr>
                  <td>วันที่จัดกิจกรรม</td>
                  <td><input type="text" class="form-control" id="txt_title" v-model="txt_event_date" ></td>
                  <td>สถานะ</td>
                  <td><select class="form-select" v-model="txt_event_status" >
                        <option value="0" >สถานะ</option>
                        <option value="ON_SALE" >เปิดจำหน่าย</option>
                        <option value="SOON" >กิจกรรมที่กำลังจะมาถึง</option>
                        <option value="HIDE" >ซ่อนกิจกรรม</option>
                        <option value="END" >ปิดการขาย</option>
                      </select></td>
                </tr>
                <tr>
                  <td>วันที่เปิดจำหน่าย</td>
                  <td><input type="text" class="form-control" id="txt_title" v-model="txt_on_sale_start" ></td>
                  <td>วันที่ปิดจำหน่าย</td>
                  <td><input type="text" class="form-control" id="txt_title" v-model="txt_on_sale_end" ></td>
                </tr>
                <tr>
                  <td>Google Drive url</td>
                  <td><input type="text" class="form-control" id="txt_google_drive" v-model="txt_google_drive" ></td>
                  <td>Line Group url</td>
                  <td><input type="text" class="form-control" id="txt_line_group" v-model="txt_line_group" ></td>
                </tr>
                <tr>
                  <td>จำนวนช่างภาพ</td>
                  <td><input type="number" class="form-control" id="txt_number_of_photographer" v-model="txt_number_of_photographer" ></td>
                  <td>เปิดให้ช่างภาพดูรายงาน</td>
                  <td>
                    <select class="form-select" v-model="txt_is_photographer_view_report" >
                        <option value="N" >ปิด</option>
                        <option value="Y" >เปิด</option>
                      </select>
                  </td>
                </tr>
                <tr>
                  <td>ข้อความประชาสัมพันธ์</td>
                  <td colspan="3">
                    <textarea v-model="txt_post_caption" rows="10"></textarea>
                  </td>
                  
                </tr>
                <tr>
                  <td>รูปแบบกิจกรรม</td>
                  <td>
                    <select class="form-select" v-model="txt_is_lock_user" >
                        <option value="N" >Open</option>
                        <option value="Y" >Lock User</option>
                      </select>
                  </td>
                  <td>อัปโหลด</td>
                  <td>
                    <select class="form-select" v-model="txt_uploadable" >
                        <option value="N" >ปิดให้อัปโหลด</option>
                        <option value="Y" >เปิดให้อัปโหลด</option>
                      </select>
                  </td>
                </tr>
                <tr>
                  <td>รูปแบบการขาย</td>
                  <td>
                    <select class="form-select" v-model="txt_is_mhao" >
                        <option value="N" >กิจกรรมขายแยก</option>
                        <option value="Y" >กิจกรรมขายเหมา</option>
                      </select>
                  </td>
                  <td>ราคาเหมา</td>
                  <td>
                    <input type="number" class="form-control" id="txt_expenses" v-model="txt_mhao_price" >
                  </td>
                </tr>
                <tr>
                  <td>ข้อความเหมา</td>
                  <td>
                    <input type="text" class="form-control" id="txt_expenses" v-model="txt_caption_button_buy_all" >
                  </td>
                  <td>ค่าใช้จ่าย</td>
                  <td>
                    <input type="number" class="form-control" id="txt_expenses" v-model="txt_expenses" >
                  </td>
                </tr>
                <tr>
                  <td>ราคาจำหน่าย</td>
                  <td colspan="3">
                    <p v-if="this.package.length==0"  style="color: red;">รอเพิ่มข้อมูล</p>
                      <span
                        v-for="(_package, index) in this.package"
                        :key="index"
                        class="btn btn-outline-info"
                        ><b>{{ _package.name }}</b>
                      </span>
                  </td>
                </tr>
                <tr >
                  <td>ลายน้ำแนวนอน</td>
                  <td style="background-color: rgb(155, 155, 155);">
                    <img  :src="this.txt_wtm_hori" style="height: 130px;border: 5px solid #000000;"  >

                   
                  </td>
                  <td>ลายน้ำแนวตั้ง</td>
                  <td style="background-color: rgb(155, 155, 155);">
                    <img  :src="this.txt_wtm_verti" style="height: 130px;border: 5px solid #000000;"  >
                  </td>
                </tr>
                <tr>
                  <td>กรอบแนวนอน</td>
                  <td style="background-color: rgb(155, 155, 155);">   
                    <img  :src="this.txt_event_frame_hori" style="height: 130px;border: 5px solid #000000;"  >
                  </td>
                  <td>กรอบแนวตั้ง</td>
                  <td style="background-color: rgb(155, 155, 155);">
                    <img  :src="this.txt_event_frame_verti" style="height: 130px;border: 5px solid #000000;"  >
                  </td>
                </tr>
                <tr>
                  <td>Note</td>
                  <td colspan="3">
                    <textarea v-model="txt_remarks" rows="5"></textarea>
                  </td>
                  
                </tr>
                
                <tr>
                  <td colspan="4" style="text-align: center;">
                    <button
                    type="button"
                    @click.prevent="eventUpdate()"
                    class="btn btn-primary"
                  >
                    <vue-feather type="save" size="18"> </vue-feather>
                    บันทึกข้อมูล
                  </button>&nbsp;
                  <button type="button" @click.prevent="events()" class="btn btn-primary" >
                <vue-feather type="refresh-cw" size="18"> </vue-feather> รีเซ็ต
              </button>
                  </td>
                  
                </tr>
                  
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <div class="header-top">

              <h5 class="m-0"><b>ค้นหาช่างภาพเพื่อเพิ่มเข้าร่วมกิจกรรม</b></h5>
              <div class="m-0"></div>
              <div class="m-0"><form class="form-inline">
                <div class="form-group mb-2">
                  รหัสช่างภาพ(ตัวเลข 4 ตัว)
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <input type="text" class="form-control" id="txt_photo_search_mapping" v-model="txt_photo_search_mapping" placeholder="ป้อนรหัสช่างภาพ 4 ตัว เพื่อค้นหา">
                </div>
                <button
                type="submit"
                @click.prevent="photoSearchMapping()"
                @submit.prevent="photoSearchMapping()"
                class="btn btn-primary"
              >
                <vue-feather type="search" size="20"> </vue-feather> ค้นหา 
              </button>
              </form>
            </div>
            </div>
          </div>
          
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th style="text-align: center"><b>รหัสช่างภาพ</b></th>
                  <th style="text-align: left"><b>ชื่อ</b></th>
                  <th style="text-align: left"><b>ชื่อเพจ</b></th>
                  <th style="text-align: center"><b>เพิ่ม</b></th>
                </tr>
              </thead>
              <tbody v-if="this.loadding_photo_search_mapping === 'Y'">
                <tr>
                  <td colspan="6">
                    <div class="d-flex justify-content-center">
                      <img
                        style="align-items: center; width: 60px"
                        src="../../assets/images/running_loading.svg"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="this.data_photo_search_mapping.length === 0 && this.loadding_photo_search_mapping === 'N'">
                <tr>
                  <td colspan="6">
                    <div class="d-flex justify-content-center">ไม่พบข้อมูล หรืออาจจะเพิ่มข้อมูลช่างภาพท่านนี้เรียบร้อยแล้ว</div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="this.data_photo_search_mapping.length !== 0">
                <tr v-for="item in this.data_photo_search_mapping" :key="item">
                  <td scope="row" style="text-align: center">
                    {{ item.user_id_text }}
                  </td>
                  <td scope="row" style="text-align: left">
                    {{ item.name }} {{ item.sername }}
                  </td>
                  <td scope="row" style="text-align: left">
                    {{ item.display_name }}
                  </td>
                  <td scope="row" style="text-align: center">
                    
                    <button
                      type="submit"
                      @click.prevent="addPhotoEventMapping(item.user_id)"
                      class="btn btn-success"
                    >
                      <vue-feather type="plus-circle" size="20"> </vue-feather> เพิ่ม 
                    </button>
                
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <div class="header-top">
              <h5 class="m-0"><b>ช่างภาพที่เข้าร่วมกิจกรรม</b></h5>
              <button
                type="button"
                @click.prevent="photoParticipate()"
                class="btn btn-primary"
              >
                <vue-feather type="refresh-cw" size="18"> </vue-feather>
                Refresh
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th style="text-align: center"><b>ลำดับ</b></th>
                  <th style="text-align: center"><b>รหัสช่างภาพ</b></th>
                  <th style="text-align: left"><b>ชื่อ</b></th>
                  <th style="text-align: left"><b>ชื่อเพจ</b></th>
                  <th style="text-align: center"><b>ลบ</b></th>
                </tr>
              </thead>
              <tbody v-if="this.loadding_photo_participate === 'Y'">
                <tr>
                  <td colspan="6">
                    <div class="d-flex justify-content-center">
                      <img
                        style="align-items: center; width: 60px"
                        src="../../assets/images/running_loading.svg"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="this.data_photo_participate.length === 0 && this.loadding_photo_participate === 'N'">
                <tr>
                  <td colspan="6">
                    <div class="d-flex justify-content-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="this.data_photo_participate.length !== 0">
                <tr v-for=" item, index in this.data_photo_participate" :key="item">
                  <td scope="row" style="text-align: center">
                    {{ index + 1 }}
                  </td>
                  <td scope="row" style="text-align: center">
                    {{ item.user_id_text }}
                  </td>
                  <td scope="row" style="text-align: left">
                    {{ item.name }} {{ item.sername }}
                  </td>
                  <td scope="row" style="text-align: left">
                    {{ item.display_name }}
                  </td>
                  <td scope="row" style="text-align: center">
                    <button
                      type="submit"
                      @click.prevent="delPhotoEventMapping(item.user_id)"
                      class="btn btn-danger btn-sm"
                    >
                      <vue-feather type="minus-circle" size="15"> </vue-feather> ลบ 
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <div class="header-top">

              <h5 class="m-0"><b>ค้นหาผู้ดูแลกิจกรรม</b></h5>
              <div class="m-0"></div>
              <div class="m-0"><form class="form-inline">
                <div class="form-group mb-2">
                  รหัสช่างภาพ(ตัวเลข 4 ตัว)
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <input type="text" class="form-control" id="txt_owner_search_mapping" v-model="txt_owner_search_mapping" placeholder="ป้อนรหัสช่างภาพ 4 ตัว เพื่อค้นหา">
                </div>
                <button
                type="submit"
                @click.prevent="ownerSearchMapping()"
                @submit.prevent="ownerSearchMapping()"
                class="btn btn-primary"
              >
                <vue-feather type="search" size="20"> </vue-feather> ค้นหา 
              </button>
              </form>
            </div>
            </div>
          </div>
          
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th style="text-align: center"><b>รหัสช่างภาพ</b></th>
                  <th style="text-align: left"><b>ชื่อ</b></th>
                  <th style="text-align: left"><b>ชื่อเพจ</b></th>
                  <th style="text-align: center"><b>เพิ่ม</b></th>
                </tr>
              </thead>
              <tbody v-if="this.loadding_owner_search_mapping === 'Y'">
                <tr>
                  <td colspan="6">
                    <div class="d-flex justify-content-center">
                      <img
                        style="align-items: center; width: 60px"
                        src="../../assets/images/running_loading.svg"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="this.data_owner_search_mapping.length === 0 && this.loadding_owner_search_mapping === 'N'">
                <tr>
                  <td colspan="6">
                    <div class="d-flex justify-content-center">ไม่พบข้อมูล หรืออาจจะเพิ่มข้อมูลช่างภาพท่านนี้เรียบร้อยแล้ว</div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="this.data_owner_search_mapping.length !== 0">
                <tr v-for="item in this.data_owner_search_mapping" :key="item">
                  <td scope="row" style="text-align: center">
                    {{ item.user_id_text }}
                  </td>
                  <td scope="row" style="text-align: left">
                    {{ item.name }} {{ item.sername }}
                  </td>
                  <td scope="row" style="text-align: left">
                    {{ item.display_name }}
                  </td>
                  <td scope="row" style="text-align: center">
                    
                    <button
                      type="submit"
                      @click.prevent="addOwnerEventMapping(item.user_id)"
                      class="btn btn-success"
                    >
                      <vue-feather type="plus-circle" size="20"> </vue-feather> เพิ่ม 
                    </button>
                
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <div class="header-top">
              <h5 class="m-0"><b>ผู้ดูแลกิจกรรม</b></h5>
              <button
                type="button"
                @click.prevent="eventOwner()"
                class="btn btn-primary"
              >
                <vue-feather type="refresh-cw" size="18"> </vue-feather>
                Refresh
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th style="text-align: center"><b>ลำดับ</b></th>
                  <th style="text-align: center"><b>รหัสช่างภาพ</b></th>
                  <th style="text-align: left"><b>ชื่อ</b></th>
                  <th style="text-align: left"><b>ชื่อเพจ</b></th>
                  <th style="text-align: center"><b>ลบ</b></th>
                </tr>
              </thead>
              <tbody v-if="this.loadding_event_owner === 'Y'">
                <tr>
                  <td colspan="6">
                    <div class="d-flex justify-content-center">
                      <img
                        style="align-items: center; width: 60px"
                        src="../../assets/images/running_loading.svg"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="this.data_event_owner.length === 0 && this.loadding_event_owner === 'N'">
                <tr>
                  <td colspan="6">
                    <div class="d-flex justify-content-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="this.data_event_owner.length !== 0">
                <tr v-for=" item, index in this.data_event_owner" :key="item">
                  <td scope="row" style="text-align: center">
                    {{ index + 1 }}
                  </td>
                  <td scope="row" style="text-align: center">
                    {{ item.user_id_text }}
                  </td>
                  <td scope="row" style="text-align: left">
                    {{ item.name }} {{ item.sername }}
                  </td>
                  <td scope="row" style="text-align: left">
                    {{ item.display_name }}
                  </td>
                  <td scope="row" style="text-align: center">
                    <button
                      type="submit"
                      @click.prevent="delOwnerEventMapping(item.user_id)"
                      class="btn btn-danger btn-sm"
                    >
                      <vue-feather type="minus-circle" size="15"> </vue-feather> ลบ 
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div v-if="this.page_permission === 'N'">
    <div class="d-flex justify-content-center">this page is not authorized</div>
  </div>
</template>
<style scoped>
.table-fit {
  width: 1px;
}

textarea
{
  width:100%;
}
.textwrapper
{
  border:1px solid #999999;
  margin:5px 0;
  padding:3px;
}
</style>
<script>

import axios from "axios";
export default {
  data() {
    return {
      loadding: "N",

      event: {},
      package: {},
      txt_title: "",
      txt_province: "",
      txt_event_date: "",
      txt_on_sale_start: "",
      txt_on_sale_end: "",
      txt_event_status: "0",
      txt_google_drive: "",
      txt_line_group: "",
      txt_number_of_photographer: 0,
      txt_is_photographer_view_report: "N",
      txt_post_caption: "",
      txt_is_lock_user: "N",
      txt_is_mhao: "N",
      txt_mhao_price: 0,
      txt_caption_button_buy_all: "",
      txt_uploadable: "N",
      txt_remarks: "",
      text_wtm_hori: "",
      txt_wtm_verti: "",
      txt_event_frame_hori: "",
      txt_event_frame_verti: "",

      loadding_photo_participate: "N",
      data_photo_participate: [],

      loadding_photo_search_mapping: "N",
      data_photo_search_mapping: [],
      txt_photo_search_mapping: "",


      loadding_owner_search_mapping: "N",
      data_owner_search_mapping: [],
      txt_owner_search_mapping: "",

      loadding_event_owner: "N",
      data_event_owner: [],
     

      user: {},
      page_permission: null,
      token: localStorage.getItem("jwt")
    };
  },
  async created(){
    this.token = localStorage.getItem("jwt")
  },
  async mounted() {

    try {
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post("/v1/partner/permission", data,{
        headers
      });
      if (response.data.status === "success") {
        let bodyPermission = JSON.parse(JSON.stringify(response.data.body));
        this.page_permission = bodyPermission.permission

        this.user = JSON.parse(localStorage.getItem('user'))

      if(this.page_permission ==='Y'){
          this.photoParticipate();
          this.events();
          //this.systemTagProcess();
          //this.reportSymmery();
          //this.reportPerformance();

      }

      }else{
        alert("permission is not recognized by admin")
      }

      this.eventOwner()

      
    } catch (error) {
      alert("login พบข้อผิดพลาด กรุณาแจ้งผู้ดูแลระบบ")
      //this.$router.push({ path: "/auth/login" });
    }
  },
  methods: {
    async events(){
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/partner/events",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        this.event = response.data.body
        this.package = response.data.package
        if(this.event.length > 0){
          this.txt_title = this.event[0].title
          this.txt_province = this.event[0].province
          this.txt_event_date = this.event[0].event_date
          this.txt_on_sale_start = this.event[0].on_sale_start
          this.txt_on_sale_end = this.event[0].on_sale_end
          this.txt_event_status = this.event[0].event_status
          this.txt_google_drive = this.event[0].google_drive
          this.txt_line_group = this.event[0].line_group
          this.txt_number_of_photographer = this.event[0].number_of_photographer
          this.txt_is_photographer_view_report = this.event[0].is_photographer_view_report
          this.txt_post_caption = this.event[0].post_caption
          this.txt_is_lock_user = this.event[0].is_lock_user
          this.txt_expenses = this.event[0].expenses

          this.txt_is_mhao = this.event[0].is_mhao
          this.txt_mhao_price = this.event[0].mhao_price
          this.txt_caption_button_buy_all = this.event[0].caption_button_buy_all
          this.txt_uploadable = this.event[0].uploadable
          this.txt_remarks = this.event[0].remarks

          this.txt_wtm_hori = this.event[0].wtm_hori
          this.txt_wtm_verti = this.event[0].wtm_verti
          this.txt_event_frame_hori = this.event[0].event_frame_hori
          this.txt_event_frame_verti = this.event[0].event_frame_verti


          

        }else{
          alert("โหลด events พบข้อผิดพลาด!");
        }
      } else {
        alert("events พบข้อผิดพลาด!");
      }
    },
    async eventUpdate(){
      if(confirm("บันทึกข้อมูล กิจกรรม?")){
          let data = {
            event_key: this.$route.params.q,
            title: this.txt_title,
            province: this.txt_province,
            event_date: this.txt_event_date,
            on_sale_start: this.txt_on_sale_start,
            on_sale_end: this.txt_on_sale_end,
            event_status: this.txt_event_status,
            google_drive: this.txt_google_drive,
            line_group: this.txt_line_group,
            number_of_photographer: this.txt_number_of_photographer,
            is_photographer_view_report: this.txt_is_photographer_view_report,
            post_caption : this.txt_post_caption,

            is_lock_user : this.txt_is_lock_user,
            expenses : this.txt_expenses,
            is_mhao : this.txt_is_mhao,
            mhao_price : this.txt_mhao_price,

            caption_button_buy_all : this.txt_caption_button_buy_all,
            uploadable : this.txt_uploadable,
            remarks: this.txt_remarks
        };
        const headers = {
         'Authorization': 'Bearer ' + this.token
      };
        const response = await axios.post(
        "v1/partner/events-update-admin",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        alert("บักทึกข้อมูลสำเร็จ");
        this.events();
      }else {
        alert("บักทึกข้อมูล พบข้อผิดพลาด!");
      }
      }

    },

    async photoParticipate(){
      this.loadding_photo_participate = "Y";
      this.data_photo_participate = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/partner/event-user-mapping",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        this.data_photo_participate = response.data.body;

        this.loadding_photo_participate = "N";
      } else {
        alert("photo_participate พบข้อผิดพลาด!");
      }
    },
    async photoSearchMapping(){
      if(this.txt_photo_search_mapping.length === 4){
        this.loadding_photo_search_mapping = "Y";
        this.data_photo_search_mapping = [];
        let data = {
          event_key: this.$route.params.q,
          user_id: this.txt_photo_search_mapping
        };
        const headers = {
         'Authorization': 'Bearer ' + this.token
      };
        const response = await axios.post(
          "v1/partner/event-search-user-mapping",
          data,
          {
            headers
          }
        );
        if (response.data.status === "success") {
          this.data_photo_search_mapping = response.data.body;

          this.loadding_photo_search_mapping = "N";
        } else {
          alert("photo_search_mapping พบข้อผิดพลาด!");
        }
      }else{
        alert("กรุณาป้อนรหัสช่างภาพ 4 หลัก")
      }

      
    },

    async addPhotoEventMapping(_user_id){
        let data = {
          event_key: this.$route.params.q,
          user_id: _user_id,
          action: 'ADD'
        };
        const headers = {
         'Authorization': 'Bearer ' + this.token
      };
        const response = await axios.post(
          "v1/partner/event-search-user-mapping-add",
          data,
          {
            headers
          }
        );
        if (response.data.status === "success") {
          //alert("เพิ่มข้อมูล เรียบร้อย")
        } else {
          alert("เพิ่มข้อมูลช่างภาพ พบข้อผิดพลาด "+response.data.message);
        }
        this.data_photo_search_mapping = [];
        this.photoParticipate();

    },
    async delPhotoEventMapping(_user_id){
      if(confirm("ต้องการลบข้อมูลผู้เข้าร่วมกิจกรรม?")){
        let data = {
          event_key: this.$route.params.q,
          user_id: _user_id,
          action: 'DEL'
        };

        const headers = {
         'Authorization': 'Bearer ' + this.token
      };
        const response = await axios.post(
          "v1/partner/event-search-user-mapping-revoke",
          data,
          {
            headers
          }
        );
        if (response.data.status === "success") {
          //alert("ลบข้อมูล เรียบร้อย")
        } else {
          alert("ลบข้อมูลช่างภาพ พบข้อผิดพลาด "+response.data.message);
        }
        this.photoParticipate();
      }
        
    },


    async ownerSearchMapping(){
      if(this.txt_owner_search_mapping.length === 4){
        this.loadding_owner_search_mapping = "Y";
        this.data_owner_search_mapping = [];
        let data = {
          event_key: this.$route.params.q,
          user_id: this.txt_owner_search_mapping
        };

        const headers = {
         'Authorization': 'Bearer ' + this.token
      };
        const response = await axios.post(
          "v1/partner/event-search-user-mapping-owner",
          data,
          {
            headers
          }
        );
        if (response.data.status === "success") {
          this.data_owner_search_mapping = response.data.body;

          this.loadding_owner_search_mapping = "N";
        } else {
          alert("owner_search_mapping พบข้อผิดพลาด!");
        }
      }else{
        alert("กรุณาป้อนรหัสช่างภาพ 4 หลัก")
      }

      
    },

    async addOwnerEventMapping(_user_id){
        let data = {
          event_key: this.$route.params.q,
          user_id: _user_id,
          action: 'ADD'
        };

        const headers = {
         'Authorization': 'Bearer ' + this.token
      };
        const response = await axios.post(
          "v1/partner/event-search-user-mapping-add-owner",
          data,
          {
            headers
          }
        );
        if (response.data.status === "success") {
          //alert("เพิ่มข้อมูล เรียบร้อย")
        } else {
          alert("เพิ่มข้อมูลผู้ดูแล พบข้อผิดพลาด "+response.data.message);
        }
        this.data_owner_search_mapping = [];
        this.eventOwner();

    },
    async delOwnerEventMapping(_user_id){
      if(confirm("ต้องการลบข้อมูลผู้เข้าร่วมกิจกรรม?")){
        let data = {
          event_key: this.$route.params.q,
          user_id: _user_id,
          action: 'DEL'
        };

        const headers = {
         'Authorization': 'Bearer ' + this.token
      };
        const response = await axios.post(
          "v1/partner/event-search-user-mapping-revoke-owner",
          data,
          {
            headers
          }
        );
        if (response.data.status === "success") {
          //alert("ลบข้อมูล เรียบร้อย")
        } else {
          alert("ลบข้อมูลช่างภาพ พบข้อผิดพลาด "+response.data.message);
        }
        this.eventOwner();
      }
        
    },
    async eventOwner(){
      this.loadding_event_owner = "Y";
      this.data_event_owner = [];
      let data = {
        event_key: this.$route.params.q,
      };

      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/partner/event-user-owner",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        this.data_event_owner = response.data.body;

        this.loadding_event_owner = "N";
      } else {
        alert("event_owner พบข้อผิดพลาด!");
      }
    }
  }
  
};
</script>
